import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';

import Scroll from '../components/Scroll';

import config from '../../config';
import ContactSection from '../components/Contact-Section';
import scrumBoard from '../assets/img/scrum-board.png';
import janImg from '../assets/img/jan-3.png';
import dominikImg from '../assets/img/dominik-3.png';
import flightplanImg from '../assets/img/flightplan-screenshot.png';

//References and Partner Logos
import tactummotumImg from '../assets/img/references/tactummotum.png';
import impleniaImg from '../assets/img/references/implenia.png';
import postfinanceImg from '../assets/img/references/postfinance.png';
import post from '../assets/img/references/post.png';

import Particles from 'react-particles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { loadFull } from 'tsparticles';

const IndexPage = ({ location }) => {
  const services = [
    {
      title: 'Webapplikationen',
      icon: 'fa-rocket',
      text: ` Moderne und massgeschneiderte Webapplikationen zu entwickeln ist unsere Leidenschaft.
                                Wir begleiten dich von der Idee bis zum Release und darüber hinaus. Dabei legen wir grossen Wert auf die Benutzerfreundlichkeit, da für uns der Benutzer stets im Zentrum steht.`,
    },
    {
      title: 'Mobile Apps',
      icon: 'fa-sitemap',
      text: `Sei auch du Teil der riesigen Community in der mobilen Welt mit einer passenden App.
                                Erreiche damit mehr Benutzer, biete ihnen neue Möglichkeiten und erhalte zusätzliche Kanäle mit ihnen zu interargieren.`,
    },
    {
      title: 'Webseiten',
      icon: 'fa-globe',
      text: `Der erste Eindruck zählt. Dies gilt auch in der digitalen Welt. Gerne unterstützen wir dich dabei
                                einen modernen Webauftritt zu erstellen oder deinen bisherigen wieder zum Glänzen zu bringen!`,
    },
    {
      title: 'Prototyping',
      icon: 'fa-lightbulb',
      text: ` Jeder hat tausende Ideen. Viel zu wenig gehen wir näher darauf ein. Pack deinen Mut und
                                probier es aus. Wir stehen dir mit unseren Laborkitteln gerne zur Seite und setzten
                                alles daran, dass daraus mehr als nur eine Idee wird.`,
    },
    {
      title: 'Weiteres',
      icon: 'fa-heart',
      text: ` Du hast ein anderes Anliegen? Kein Problem. In einem initialen Gespräch können wir
                                gemeinsam herausfinden, wie wir dir dabei helfen können.`,
    },
  ];
  const team = [
    {
      name: 'Jan Heimberg',
      img: janImg,
      description:
        'Ausschlafen gibt es nicht.<br/>' +
        'Dein Ansprechpartner für die frühen Morgenstunden.',
      socialLinks: [
        {
          icon: ['fab', 'linkedin-in'],
          url: 'http://www.linkedin.com/in/jan-heimberg',
          bg: '#0a66c2',
          fg: 'white',
        },
      ],
    },
    {
      name: 'Dominik Schmid',
      img: dominikImg,
      description:
        'Früh ins Bett ist keine Option.<br/>' +
        'Zuständig für die Nachteinsätze.',
      socialLinks: [
        {
          icon: ['fab', 'linkedin-in'],
          url: 'https://www.linkedin.com/in/schmid-dominik/',
          bg: '#0a66c2',
          fg: 'white',
        },
      ],
    },
  ];

  const [hasRan, setHasRan] = useState(false);
  const [screenSize, setScreenSize] = useState({
    height: 0,
    width: 0,
  });
  const updateScreenSize = () => {
    setScreenSize({ width: window.innerWidth, height: window.innerHeight });
  };
  useEffect(() => {
    if (!hasRan) {
      setHasRan(true);
      updateScreenSize();
    }
    window.addEventListener('resize', updateScreenSize);
    return () => {
      window.removeEventListener('resize', updateScreenSize);
    };
  }, [hasRan, screenSize]);

  const references = [
    {
      name: 'TactumMotum',
      image: tactummotumImg,
    },
    {
      name: 'Implenia',
      image: impleniaImg,
    },
    {
      name: 'PostFinance AG',
      image: postfinanceImg,
    },
    {
      name: 'Die Schweizerische Post AG',
      image: post,
    },
  ];
  const particlesInit = useCallback(async (engine) => {
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {}, []);

  return (
    <Layout
      location={location}
      title={'Home'}
      description={`Wir sind ein junges IT-Unternehmen, das mit Frische und Herzblut gerne komplexe und herausfordernde Anforderungen umsetzt.`}
    >
      <section id="banner">
        {hasRan ? (
          <>
            <Particles
              id="particles"
              className={'particles'}
              init={particlesInit}
              loaded={particlesLoaded}
              options={{
                background: {
                  color: {
                    value: '#333333',
                  },
                },
                fullScreen: {
                  enable: false,
                  zIndex: -1,
                },
                particles: {
                  color: {
                    value: '#ffffff',
                  },
                  links: {
                    color: '#ffffff',
                    distance: 150,
                    enable: true,
                    opacity: 0.5,
                    width: 1,
                  },
                  collisions: {
                    enable: true,
                  },
                  move: {
                    directions: 'none',
                    enable: true,
                    outModes: {
                      default: 'bounce',
                    },
                    random: false,
                    speed: 2.5,
                    straight: false,
                  },
                  number: {
                    density: {
                      enable: true,
                      area: 800,
                    },
                    value: screenSize.width < 1150 ? 40 : 60,
                  },
                  opacity: {
                    value: 0.3,
                  },
                  shape: {
                    type: 'circle',
                  },
                  size: {
                    value: { min: 1, max: 1 },
                  },
                },
              }}
            />
          </>
        ) : (
          <></>
        )}

        <div className="inner">
          <h2>{config.heading}</h2>
          <p>{config.subHeading}</p>
        </div>
        <Scroll type="id" element="one">
          <a href="#one" className="more">
            Mehr erfahren
          </a>
        </Scroll>
      </section>

      <section id="one" className="wrapper style5 special">
        <div className="inner">
          <header className="major">
            <h2>Hallo! Wir sind Benso</h2>
            <p>
              Benso begleitet dich bei der Konzipierung und Programmierung von
              Software Lösungen. Wir lieben es, mit frischen und ausgeklügelten
              Ideen, komplexe und herausfordernde Anforderungen umzusetzen. Wir
              sind ein junges Team mit breiten Kompetenzen, welche wir täglich
              mit unserer Neugierde ausbauen. Ergänzt werden die Fähigkeiten
              durch ein Netzwerk von talentierten Freelancern, die auf UX und
              Design spezialisiert sind.
            </p>
            <div
              className="row gtr-50 gtr-uniform aln-center"
              style={{ paddingBottom: '1em' }}
            >
              <div className="col-6 col-12-small">
                <span className="image fit">
                  <img src={scrumBoard} alt="arbeitsmethode-scrum" />
                </span>
              </div>
            </div>
            <p>
              Gemeinsam erreicht man mehr. Nach diesem Motto setzen wir unsere
              Projekte um. Der Austausch mit den Kunden steht bei uns im Zentrum
              und ist das A und O für eine gute Lösung. Wir freuen uns, auch
              dich mit unserer Arbeitsweise begeistern zu dürfen.
            </p>
          </header>
          <ul className="features">
            {team.map((member, index) => {
              return (
                <li className="icon solid" key={index}>
                  <div className="row gtr-50 gtr-uniform">
                    <div className="col-12">
                      <span className="image fit">
                        <img src={member.img} alt={member.name} />
                      </span>
                    </div>
                    <div className="col-12">
                      <h3>
                        {member.name}{' '}
                        {member.socialLinks.map((icon, i) => (
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={icon.url}
                            key={i}
                            style={{
                              padding: '0 0.2em',
                              backgroundColor: icon.bg,
                              color: icon.fg,
                            }}
                          >
                            <FontAwesomeIcon icon={icon.icon} />
                          </a>
                        ))}
                      </h3>
                      <p
                        dangerouslySetInnerHTML={{ __html: member.description }}
                      />
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
          {/*                     <ul className="icons major">
                        <li>
                            <span className="icon fa-gem major style1">
                            </span>
                        </li>
                        <li>
                            <span className="icon fa-heart major style2">
                            </span>
                        </li>
                        <li>
                            <span className="icon solid fa-code major style3">
                            </span>
                        </li>
                    </ul> */}
        </div>
      </section>

      <section id="two" className="wrapper alt style2">
        <section className="spotlight">
          <div className="image">
            <img src={flightplanImg} alt="Flightplan des Contentboards" />
          </div>
          <div className="content">
            <h2>Contentboard</h2>
            <p>
              Mit dem Contentboard hast du deine Vorhaben stets im Blick. Durch
              einfach verständliche Visualisierungen kannst du deine komplexen
              Abläufe ganz simpel jedem aufzeigen.
            </p>
            <p>
              <Link to="/contentboard/" className="button primary">
                Mehr erfahren
              </Link>
            </p>
          </div>
        </section>
        {/* <section className="spotlight">
                                                <div className="image">
                                                <img src={pic2} alt=""/>
                                                </div>
                                                <div className="content">
                                                <h2>
                                                Tortor dolore feugiat
                                                <br/>
                                                elementum magna
                                                </h2>
                                                <p>
                                                Aliquam ut ex ut augue consectetur interdum. Donec hendrerit
                                                imperdiet. Mauris eleifend fringilla nullam aenean mi ligula.
                                                </p>
                                                </div>
                                                </section>
                                                <section className="spotlight">
                                                <div className="image">
                                                <img src={pic3} alt=""/>
                                                </div>
                                                <div className="content">
                                                <h2>
                                                Augue eleifend aliquet
                                                <br/>
                                                sed condimentum
                                                </h2>
                                                <p>
                                                Aliquam ut ex ut augue consectetur interdum. Donec hendrerit
                                                imperdiet. Mauris eleifend fringilla nullam aenean mi ligula.
                                                </p>
                                                </div>
                                                </section>*/}
      </section>

      <section id="three" className="wrapper style5 special">
        <div className="inner">
          <header className="major">
            <h2>Unsere Services</h2>
            <p>
              Auch neben unseren Hauptkompetenzen sind wir stets offen für Ideen
              und Spinnereien. Gerne helfen wir auch kurzfristig bei euren
              Herausforderungen. <br />
              Melde dich ungeniert.
            </p>
          </header>
          <ul className="features">
            {services.map((service, index) => {
              return (
                <li className={service.icon + ' icon solid'} key={index}>
                  <h3>{service.title}</h3>
                  <p>{service.text}</p>
                </li>
              );
            })}
            <li className="">
              <h3 style={{ visibility: 'hidden' }}>Kontaktiere uns</h3>
              <ul>
                <li>
                  <Link to="/kontakt/" className="button primary">
                    kontaktiere uns
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </section>
      <section className="wrapper style5 special">
        <div className="inner">
          <header className="major">
            <h2>Unsere Kunden und Partner</h2>
            <p></p>
          </header>
          <div
            className="row gtr-50 gtr-uniform aln-center"
            style={{ paddingBottom: '1em' }}
          >
            {references.map((ref) => {
              return (
                <div key={ref.name} className="col-4 col-12-small">
                  <span className="image fit" style={{ padding: '0.5em' }}>
                    <img src={ref.image} alt={ref.name} />
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <ContactSection id={'home'} />
    </Layout>
  );
};

export default IndexPage;
